import styled from 'styled-components';

import homepageBackgroundImg from '../../assets/backgroundBlur.png';
import frontCardBackgroundImg from '../../assets/backgroundCard.png';
import fullstackFeatured from '../../assets/fullstack.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IntroContainer = styled.div`
  height: 700px;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const AboutContainer = styled.div`
  width: 1040px;
  display: flex;
  background-color: #fff;
`;

export const BackgroundIntro = styled.div`
  background: url(${homepageBackgroundImg}) no-repeat center;
  background-size: cover;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const FrontCard = styled.div`
  height: 580px;
  width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${frontCardBackgroundImg}) no-repeat center;
  background-size: cover;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  justify-content: space-between;
`;

export const LanguageMenu = styled.div`
  background: #292a4a;
  width: 180px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const TextLanguage = styled.text`
  color: #f2f2f2;
`;

export const MenuButton = styled.div``;

export const HamburguerBar = styled.div`
  width: 35px;
  height: 5px;
  margin-bottom: 5px;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
`;

export const TextHello = styled.text`
  height: 100px;
  width: 540px;
  border: 3px solid #9ea0e8;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f2f2f2;
  font-size: 60px;
  margin-top: 64px;
  margin-bottom: 80px;
`;

export const TextContent = styled.text`
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: #f2f2f2;
`;

export const TextContentAlert = styled.text`
  background-color: #f2f2f230;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 40px;
  padding: 5px;
  font-weight: 600;
  color: #eeee00;
`;

export const IconFrontCardContainer = styled.div`
  padding-top: 70px;
  display: flex;
  justify-content: center;
`;

export const FrontContent = styled.div``;

export const AboutContent = styled.div`
  margin: 40px 0 40px 120px;
  width: 560px;
`;

export const AboutTitleContainer = styled.div`
  border-left: 2px solid #9ea0e8;
  margin-bottom: 20px;
`;

export const AboutTitle = styled.text`
  margin-left: 20px;
  font-size: 36px;
  color: #292a4a;
`;

export const AboutCitation = styled.text`
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  display: flex;
  margin-bottom: 15px;
`;

export const AboutText = styled.text`
  font-size: 16px;
  line-height: 18px;
  display: flex;
  margin-bottom: 15px;
  text-align: justify;
`;

export const ButtonDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 180px;
  background-color: #58545b;
  border-radius: 5px;
  padding: 0 20px;
  color: #f2f2f2;
`;

export const ButtonText = styled.text`
  font-size: 14px;
`;

export const KnowledgeContainer = styled.div`
  width: 1040px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #58545b;
  color: #f2f2f2;
  padding-top: 40px;
`;

export const FundamentalContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TitleKnowledge = styled.text`
  font-size: 16px;
  font-weight: 600;
  padding: 20px;
`;

export const WevDevContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const MobDevContainer = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const FeaturedContainer = styled.div`
  width: 1040px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 40px;
`;

export const FeaturedContent = styled.div`
  margin: 40px 0 40px 120px;
  width: 560px;
`;

export const FeatureTitleContainer = styled.div`
  border-left: 2px solid #9ea0e8;
  margin-bottom: 20px;
`;

export const FeaturedTitle = styled.text`
  margin-left: 20px;
  font-size: 36px;
  color: #292a4a;
`;

export const FeaturedText = styled.text`
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
  display: flex;
  margin-bottom: 15px;
`;

export const FeaturedCardContainer = styled.div`
  width: 1040px;
  display: flex;
  flex-wrap: wrap;
`;

export const FeaturedCard = styled.div`
  display: flex;
  width: 260px;
  height: 260px;
  background: url(${fullstackFeatured}) no-repeat center/100%;
  background-color: #e83f5b;
  border: 2px solid orange;
`;

export const ContactContainer = styled.div`
  width: 1040px;
  display: flex;
  background-color: #fff;
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #58545b;
  padding-top: 40px;
  color: #f2f2f2;
  width: 420px;
  padding-left: 120px;
  padding-right: 40px;
`;

export const ContactFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 40px;
`;

export const ContactInfoTitleContainer = styled.div`
  border-left: 2px solid #9ea0e8;
  margin-bottom: 20px;
`;

export const ContactInfoTitle = styled.text`
  margin-left: 20px;
  font-size: 36px;
  color: #f2f2f2;
`;

export const ContactInfoText = styled.text`
  width: 180px;
  font-size: 14px;
  font-style: italic;
  line-height: 8px;
  display: flex;
  margin-bottom: 15px;
`;

export const ContactInfoSocialContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  width: 140px;
  display: flex;
  justify-content: space-between;
`;

export const ContactFormInput = styled.input`
  width: 230px;
  height: 30px;
`;

export const ContactFormMessageInput = styled.textarea`
  width: 590px;
  height: 90px;
  vertical-align: top;
  resize: none;
`;

export const ContactIconContainer = styled.div`
  margin-right: 15px;
`;

export const ContactMessageContainer = styled.div`
  display: flex;
`;

export const ContactFormButton = styled.button``;
