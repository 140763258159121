import React from 'react';
import {} from 'react-router-dom';
import { ImDownload, ImTwitter, ImLinkedin, ImInstagram } from 'react-icons/im';

import { FiMail, FiMessageSquare, FiUser } from 'react-icons/fi';
import logoImg from '../../assets/logo/devtrails.svg';
import iconArrowCircleDown from '../../assets/icons/arrow_circle_down.png';
import iconAlgorithm from '../../assets/icons/algorithm.png';
import iconLaptop from '../../assets/icons/laptop.png';
import iconMobile from '../../assets/icons/mobile.png';

import {
  Container,
  FrontCard,
  BackgroundIntro,
  MenuContainer,
  LanguageMenu,
  MenuButton,
  HamburguerBar,
  FrontContent,
  TextHello,
  TextContent,
  TextLanguage,
  IconFrontCardContainer,
  AboutContainer,
  AboutContent,
  IntroContainer,
  AboutTitle,
  AboutTitleContainer,
  AboutCitation,
  AboutText,
  ButtonDownload,
  ButtonText,
  KnowledgeContainer,
  FundamentalContainer,
  TitleKnowledge,
  WevDevContainer,
  MobDevContainer,
  FeaturedContainer,
  FeaturedTitle,
  FeaturedText,
  FeatureTitleContainer,
  FeaturedContent,
  FeaturedCard,
  FeaturedCardContainer,
  ContactContainer,
  ContactInfoContainer,
  ContactInfoTitle,
  ContactInfoText,
  ContactInfoSocialContainer,
  ContactFormContainer,
  ContactFormInput,
  ContactInfoTitleContainer,
  ContactFormMessageInput,
  ContactIconContainer,
  ContactMessageContainer,
  TextContentAlert,
} from './styles';

const HomePage: React.FC = () => {
  return (
    <Container>
      <IntroContainer>
        <BackgroundIntro>
          <FrontCard>
            <MenuContainer>
              <img src={logoImg} alt="DevTrails" />
              <LanguageMenu>
                <TextLanguage>english</TextLanguage>
                <TextLanguage> | </TextLanguage>
                <TextLanguage>português</TextLanguage>
              </LanguageMenu>
              <MenuButton>
                <HamburguerBar />
                <HamburguerBar />
                <HamburguerBar />
              </MenuButton>
            </MenuContainer>
            <TextHello>Hello! I&apos;m Ricardo</TextHello>
            <FrontContent>
              <TextContent>Welcome to my website!</TextContent>
              <TextContent>
                I&apos;m a Full Stack Web and Mobile Developer.
              </TextContent>
              <TextContentAlert>
                This Website is under construction!
                <p>I&apos;m working hard to make this fully functional.</p>
              </TextContentAlert>
              <IconFrontCardContainer>
                <img src={iconArrowCircleDown} alt="" />
              </IconFrontCardContainer>
            </FrontContent>
          </FrontCard>
        </BackgroundIntro>
      </IntroContainer>
      <AboutContainer>
        <AboutContent>
          <AboutTitleContainer>
            <AboutTitle>ABOUT ME</AboutTitle>
          </AboutTitleContainer>
          <AboutCitation>
            I am a full stack web and mobile developer based in Porto Alegre-RS,
            Brazil.
          </AboutCitation>
          <AboutText>
            Since October 2019, I study computer science and web development,
            already in December of that year, I started studying Java and
            Javascript languages in the courses offered by CoderBr.
          </AboutText>
          <AboutText>
            In August 2020 I started learning a Javascript, NodeJS and React
            development stack in the Rocketseat course.
          </AboutText>
          <AboutText>
            In this course I was able to develop my skills in web development
            and mobile applications, using the tools of a robust development
            stack that allows integration between backend, frontend and mobile.
          </AboutText>
          <AboutText>
            On this website, you can see the results of this trail, accessing my
            featured works and the articles published on my blog.
          </AboutText>
          <ButtonDownload>
            <ImDownload />
            <ButtonText>DOWNLOAD CV</ButtonText>
          </ButtonDownload>
        </AboutContent>
      </AboutContainer>
      <KnowledgeContainer>
        <FundamentalContainer>
          <img src={iconAlgorithm} alt="Fundamental Knowledge" />
          <TitleKnowledge>Fundamental Knowledge</TitleKnowledge>
          Algorithm and Data Structure, Design Pattern and Programming
          Principles.
        </FundamentalContainer>
        <WevDevContainer>
          <img src={iconLaptop} alt="Web Development" />
          <TitleKnowledge>Web Development</TitleKnowledge>
          Development of systems for websites, integration with backend services
          and unitary tests.
        </WevDevContainer>
        <MobDevContainer>
          <img src={iconMobile} alt="App Development" />
          <TitleKnowledge>App Development</TitleKnowledge>
          Development of applications and systems for mobile devices with
          javascript, nodejs and react native. Integration with backend and
          frontend services.
        </MobDevContainer>
      </KnowledgeContainer>
      <FeaturedContainer>
        <FeaturedContent>
          <FeatureTitleContainer>
            <FeaturedTitle>Featured Works</FeaturedTitle>
          </FeatureTitleContainer>
          <FeaturedText>
            During my study work, I had the opportunity to create several
            projects, in which I was able to explore different tools and develop
            my programming skills.
          </FeaturedText>
        </FeaturedContent>
        <FeaturedCardContainer>
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
          <FeaturedCard />
        </FeaturedCardContainer>
      </FeaturedContainer>
      <ContactContainer>
        <ContactInfoContainer>
          <ContactInfoTitleContainer>
            <ContactInfoTitle>Contact</ContactInfoTitle>
          </ContactInfoTitleContainer>
          <ContactInfoText>Ricardo Granvilla</ContactInfoText>
          <ContactInfoText>rgranvilla@gmail.com</ContactInfoText>
          <ContactInfoText>Porto Alegre - RS / Brazil</ContactInfoText>
          <ContactInfoSocialContainer>
            <ImTwitter size="30px" />
            <ImLinkedin size="30px" />
            <ImInstagram size="30px" />
          </ContactInfoSocialContainer>
        </ContactInfoContainer>
        <ContactFormContainer>
          <ContactMessageContainer>
            <ContactIconContainer>
              <FiUser />
            </ContactIconContainer>
            <ContactFormInput name="name" placeholder="Name" />
          </ContactMessageContainer>
          <ContactMessageContainer>
            <ContactIconContainer>
              <FiMail />
            </ContactIconContainer>
            <ContactFormInput name="email" placeholder="E-mail" />
          </ContactMessageContainer>
          <ContactMessageContainer>
            <ContactIconContainer>
              <FiMessageSquare />
            </ContactIconContainer>
            <ContactFormMessageInput name="message" placeholder="Message" />
          </ContactMessageContainer>
        </ContactFormContainer>
      </ContactContainer>
    </Container>
  );
};

export default HomePage;
